import React from 'react'

interface TimerProviderProps {
  children?: React.ReactElement | undefined
}

export const TimerContext = React.createContext<Date>(new Date())

export const TimerProvider = ({ children }: TimerProviderProps) => {
  const surveyStartTime = new Date()

  return (
    <TimerContext.Provider value={surveyStartTime}>
      {children}
    </TimerContext.Provider>
  )
}
