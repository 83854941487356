import { BrazilSurvey, MainPage, NewSurvey } from 'pages/main'
import MainRouteConstants from 'routes/main/constants'
import { RouteDefinition } from 'routes/types'

const MainRoutesDefinition: RouteDefinition[] = [
  {
    path: MainRouteConstants.CANADA,
    component: NewSurvey,
    exact: true
  },
  {
    path: MainRouteConstants.US,
    component: NewSurvey,
    exact: true
  },
  {
    path: MainRouteConstants.BR,
    component: BrazilSurvey,
    exact: true
  },
  {
    path: MainRouteConstants.MAIN,
    component: MainPage,
    exact: false
  }
]
export default MainRoutesDefinition
