import RootComponent from 'components/root-component'
import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router'
import * as RouteDefinitions from 'routes'

const dashboardRoutes = Object.values(RouteDefinitions)
  .flat()
  .map(({ component, path, exact }) => (
    <Route component={component} path={path} exact={exact} key={path} />
  ))

const RootRouteComponent = () => {
  return (
    <RootComponent>
      <Suspense fallback={<div />}>
        <Switch>{dashboardRoutes}</Switch>
      </Suspense>
    </RootComponent>
  )
}

export default RootRouteComponent
