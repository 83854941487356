import { ExternalPage } from 'pages/external'
import { RouteDefinition } from 'routes/types'
import ExternalRouteConstants from './constants'

const ExternalRoutesDefinition: RouteDefinition[] = [
  {
    path: ExternalRouteConstants.EXTERNAL,
    component: ExternalPage,
    exact: true
  }
]

export default ExternalRoutesDefinition
