import { CountryEnum } from 'static/questions'
import frenchCanadianTranslations from './translations/fr-CA.json'
import portugeseTranslations from './translations/pt-BR.json'
import spanishTranslations from './translations/es-US.json'

export const stringToObjectReference = (
  source: { [key: string]: any },
  scope: string
) => scope.split('.')?.reduce((acc, cur) => acc[cur], source) || scope

export const getKeyFromValue = (
  object: { [key: string]: any },
  value: string
): string => Object.keys(object).find(key => object[key] === value)

export enum SupportedLanguages {
  fr = 'fr',
  pt = 'pt',
  es = 'es'
}

export const availableTranslations = {
  [CountryEnum.CA]: [SupportedLanguages.fr],
  [CountryEnum.BR]: [SupportedLanguages.pt],
  [CountryEnum.US]: [SupportedLanguages.es]
}

export const translationsMap = {
  [SupportedLanguages.fr]: frenchCanadianTranslations,
  [SupportedLanguages.pt]: portugeseTranslations,
  [SupportedLanguages.es]: spanishTranslations
}
