import au from './au.json'
import us from './us.json'
import ca from './ca.json'
import br from './br.json'

export enum CountryEnum {
  AU = 'AU',
  US = 'US',
  CA = 'CA',
  EU = 'EU',
  BR = 'BR'
}

export const CountryCodeToQuestion = {
  [CountryEnum.AU]: au,
  [CountryEnum.US]: us,
  [CountryEnum.CA]: ca,
  [CountryEnum.BR]: br
}
