import React, { createContext, ReactElement, useContext } from 'react'
import { startCase } from 'lodash'
import {
  availableTranslations,
  getKeyFromValue,
  stringToObjectReference,
  SupportedLanguages,
  translationsMap
} from 'i18n/helpers'
import { useLocation } from 'react-router-dom'

export const TranslationContext = createContext(null)

interface TranslationProviderProps {
  children?: ReactElement | undefined
}

export const TranslationProvider = ({ children }: TranslationProviderProps) => {
  const location = useLocation()
  const usersLanguage = window.navigator.language
  const countryCode = startCase(location.pathname).toUpperCase()

  return (
    <TranslationContext.Provider value={[usersLanguage, countryCode]}>
      {children}
    </TranslationContext.Provider>
  )
}

export const useTranslation = (scope?: string) => {
  const [usersLanguage, countryCode] = useContext(TranslationContext)

  const translationsForUsersLanguage = Object.keys(SupportedLanguages).find(
    lang => new RegExp(`^${lang}\\b`).test(usersLanguage)
  )

  const canTranslateSurvey = availableTranslations[countryCode]?.includes(
    translationsForUsersLanguage
  )

  /**
   * @param original - The original string to be translated
   * @param options.scope - Overrides the default scope set in `useTranslation` param
   * @param options.reverse - Attempts to translate already translated strings back to source language. Returns original if it can not be reverse-translated
   */
  return (
    original: string,
    options?: { scope?: string; reverse?: boolean }
  ) => {
    if (!canTranslateSurvey) return original
    if (!translationsForUsersLanguage) return original
    if (Number(original) || original === '0') return original

    const relevantScope = options?.scope || scope

    if (!relevantScope) {
      throw new Error(`Must provide a scope for "${original}" to be translated`)
    }

    const relevantTranslations = translationsMap[translationsForUsersLanguage]
    const convertedScope = stringToObjectReference(
      relevantTranslations,
      relevantScope
    )

    if (options?.reverse) {
      const keyFromValue = getKeyFromValue(convertedScope, original)

      if (keyFromValue) return keyFromValue

      if (!convertedScope[original]) {
        throw new Error(
          `Failed to find "${original}" in scope: ${relevantScope}`
        )
      }

      return original
    }

    if (!convertedScope[original]) {
      throw new Error(`Failed to find "${original}" in scope: ${relevantScope}`)
    }

    return convertedScope[original]
  }
}
