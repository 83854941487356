import 'App.less'
import 'bootstrap/dist/css/bootstrap.min.css'
import { createBrowserHistory } from 'history'
import { TranslationProvider } from 'i18n'
import { TimerProvider } from 'components/timer-context'
import React from 'react'
import { Router } from 'react-router-dom'
import { RootRouteComponent } from 'routes/root'
import './wdyr'

export const browserHistory = createBrowserHistory()

const App = () => (
  <Router history={browserHistory}>
    <TimerProvider>
      <TranslationProvider>
        <RootRouteComponent />
      </TranslationProvider>
    </TimerProvider>
  </Router>
)

export default App
